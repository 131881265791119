import type { IPagination, ISearchable, ITreeNode } from 'shared/types'
import { client } from '../clients'

const createRecord = (record: ITreeNode) => client.post('/nodes', record)

const getRecord = (recordId: string) =>
  client.get<ITreeNode>(`/nodes/${recordId}`)

const getList = (page: number) => client.get<IPagination<ITreeNode>>('/nodes', {
  params: { page }
})

const updateRecord = (record: ITreeNode) =>
  client.put(`/nodes/${record.id}`, record)

const removeRecord = (recordId: string) => client.delete(`/nodes/${recordId}`)

const search = ({ end, ...params }: ISearchable & { end?: boolean }) => {
  const method = end ? 'all-with-modelId' : 'all'

  return client.get<ITreeNode[]>(`/nodes/${method}`, { params })
}

export const treeNodes = {
  record: {
    create: createRecord,
    get: getRecord,
    update: updateRecord,
    remove: removeRecord
  },
  getList,
  search
}
