import { combine, sample } from 'effector'

import { $formFields, reset, submit } from 'pages/login'
import { UserRoles, type AppUserData } from 'shared/types'
import { domain } from './_init'
import {
  addAuthInterceptorsFx,
  getUserFx,
  loginFx,
  logoutFx,
  setTokenFx
} from './effects'
import { initializeAuthModel } from './events'

/** Stores */

/** Store: authorized user data */
const $userData = domain.createStore<AppUserData>({
  email: '',
  role: null,
  userData: '',
  userName: ''
})
  .on(getUserFx.doneData, (_, { data }) => data)

const $isLoggedIn = domain.createStore(false)
  .on([getUserFx.fail, logoutFx.done], () => false)
  .on(getUserFx.done, () => true)

/** Computed fields */

const $loading = combine([
  addAuthInterceptorsFx.pending,
  getUserFx.pending,
  loginFx.pending,
  logoutFx.pending
], states => states.some(Boolean))

const $userRole = $userData.map(data => data.role)

const $isAdmin = $userData.map(data => UserRoles.Administrator === data.role)

/** End of computed fields */

/** Watchers */

/** Attach auth interceptors */
sample({
  clock: initializeAuthModel,
  target: addAuthInterceptorsFx
})

/** Set auth token to local storage */
sample({
  clock: loginFx.doneData,
  fn: ({ data }) => data,
  target: setTokenFx
})

/** Attach auth interceptors when token received */
sample({
  clock: setTokenFx.done,
  target: addAuthInterceptorsFx
})

/** Get user data */
sample({
  clock: addAuthInterceptorsFx.done,
  target: getUserFx
})

/** Reset form on auth is a success */
sample({
  clock: loginFx.done,
  target: reset
})

/** Submit form */
sample({
  clock: submit,
  source: $formFields,
  filter: formFields => Object.values(formFields).every(Boolean),
  target: loginFx
})

/** End of watchers */

export {
  domain,
  $isAdmin,
  $isLoggedIn,
  $loading,
  $userData,
  $userRole,
  initializeAuthModel,
  loginFx,
  logoutFx
}
