import { useUnit } from 'effector-react'
import type { ReactNode } from 'react'

import { $loading } from 'processes/auth'
import { AppLoader } from 'shared/ui'

export const withLoader = (component: () => ReactNode) => () => {
  const loading = useUnit($loading)

  if (loading) return <AppLoader />

  return component()
}
