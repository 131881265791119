import {
  Component,
  ErrorInfo,
  type PropsWithChildren,
  type ReactNode
} from 'react'

import { seqLog } from 'shared/lib'

export const withLogger = (component: () => ReactNode) => () => (
  <ErrorBoundary>
    {component()}
  </ErrorBoundary>
)

type ErrorBoundaryProps = PropsWithChildren<{ fallback?: ReactNode }>

class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch (error: Error, info: ErrorInfo) {
    seqLog.error(
      `[${error.name}]: ${error.message}`,
      { ComponentStack: info.componentStack },
      error.stack
    )
  }

  render() {
    if (this.state.hasError) return this.props.fallback ?? null

    return this.props.children
  }
}
