import styled from '@emotion/styled'
import { Menu, type MenuProps } from 'antd'
import { useUnit } from 'effector-react'
import { createElement, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import * as authModel from 'processes/auth'
import { menuItems } from './items'

export const AppNavigation = () => {
  const location = useLocation()
  const userRole = useUnit(authModel.$userRole)

  const items = useMemo(() => {
    const result: NonNullable<MenuProps>['items'] = []

    for (const { icon, name, route, roles } of menuItems) {
      const renderIcon = createElement(icon)

      const menuItem = {
        icon: renderIcon,
        key: route,
        label: (
          <Link to={route}>
            {name}
          </Link>
        )
      }

      if (
        !roles ||
        (userRole && roles.includes(userRole))
      ) result.push(menuItem)
    }

    return result
  }, [userRole])

  return (
    <Navigation
      items={items}
      mode='inline'
      selectedKeys={[`/${location.pathname.split('/')?.[1]}`]}
    />
  )
}

const Navigation = styled(Menu)`
  border-right: 0;
  height: 100%;
`
