import type { ICity, ISearchable } from 'shared/types'
import { client } from '../clients'

const search = (params: ISearchable) => client.get<ICity[]>('/cities', {
  params
})

export const cities = {
  search
}
