import type { IPagination, IPrice } from 'shared/types'
import { client } from '../clients'

export interface IFilteredPricesParams {
  cityName: string
  deviceName: string
  gradeName: string
  partnerName: string
  page: number
  size: number
}

const createRecord = (record: IPrice) => client.post('/prices', record)

const getList = (page: number) => client.get<IPagination<IPrice>>('/prices', {
  params: { page }
})

const getFilteredList = (params: Partial<IFilteredPricesParams>) =>
  client.get<IPagination<IPrice>>('/prices/filter-prices', { params })

const getRecord = (recordId: string) => client.get(`/prices/${recordId}`)

const updateRecord = (record: IPrice) =>
  client.put(`/prices/${record.id}`, record)

const removeRecord = (recordId: string) => client.delete(`/prices/${recordId}`)

export const prices = {
  record: {
    create: createRecord,
    get: getRecord,
    update: updateRecord,
    remove: removeRecord
  },
  getFilteredList,
  getList
}
