import type { AppAuthPayload } from 'shared/types'
import { domain } from './_init'
import { reset, setFormFields, submit } from './events'

/** Stores */

const $formFields = domain.createStore<AppAuthPayload>({
  password: '',
  username: ''
})
  .on(setFormFields, (form, payload) => ({ ...form, ...payload }))
  .reset(reset)

/** End of stores */

export {
  $formFields,
  reset,
  setFormFields,
  submit
}
