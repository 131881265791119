import type { IPagination, ITreeNodeTest } from 'shared/types'
import { client } from '../clients'

const createRecord = (record: ITreeNodeTest) =>
  client.post('/nodetests', record)

const getList = (page: number) =>
  client.get<IPagination<ITreeNodeTest>>('/nodetests', { params: { page } })

const getRecord = (recordId: string) =>
  client.get<ITreeNodeTest>(`/nodetests/${recordId}`)

const updateRecord = (record: ITreeNodeTest) =>
  client.put(`/nodetests/${record.id}`, record)

const removeRecord = (recordId: string) =>
  client.delete(`/nodetests/${recordId}`)

export const nodeTests = {
  record: {
    create: createRecord,
    get: getRecord,
    update: updateRecord,
    remove: removeRecord
  },
  getList
}
