import compose from 'compose-function'

import { withAntd } from './with-antd'
import { withAuth } from './with-auth'
import { withLoader } from './with-loader'
import { withLogger } from './with-logger'
import { withHelmet } from './with-helmet'
import { withRouter } from './with-router'

export const withProviders = compose(
  withAntd,
  withAuth,
  withHelmet,
  withLoader,
  withLogger,
  withRouter
)
