import type { ISearchable, IService } from 'shared/types'
import { client } from '../clients'

const search = (params: ISearchable) => client.get<IService[]>('/services', {
  params
})

export const services = {
  search
}
