import {
  BranchesOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FileDoneOutlined,
  HomeOutlined,
  MobileOutlined,
  StockOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UserOutlined
} from '@ant-design/icons'
import type { IconDefinition } from '@ant-design/icons-svg/lib/types'

import { type AntdIconComponent, UserRoles } from 'shared/types'

interface AppMenuItem {
  icon: AntdIconComponent<{ icon: IconDefinition }>
  name: string
  route: string
  roles?: UserRoles[]
}

export const menuItems: AppMenuItem[] = [
  {
    icon: HomeOutlined,
    name: 'Домашняя страница',
    route: '/'
  },
  {
    icon: FileDoneOutlined,
    name: 'Тесты',
    roles: [UserRoles.Administrator],
    route: '/tests'
  },
  {
    icon: UnorderedListOutlined,
    name: 'Вопросы',
    roles: [UserRoles.Administrator],
    route: '/tree-node-tests'
  },
  {
    icon: BranchesOutlined,
    name: 'Группы моделей',
    roles: [UserRoles.Administrator],
    route: '/tree-nodes'
  },
  {
    icon: UserOutlined,
    name: 'Пользователи',
    roles: [UserRoles.Administrator],
    route: '/users'
  },
  {
    icon: ToolOutlined,
    name: 'Цены услуг',
    roles: [UserRoles.Administrator],
    route: '/service-prices'
  },
  {
    icon: MobileOutlined,
    name: 'Устройства',
    roles: [UserRoles.Administrator],
    route: '/devices'
  },
  {
    icon: DatabaseOutlined,
    name: 'Память устройств',
    roles: [UserRoles.Administrator],
    route: '/memories'
  },
  {
    icon: DollarOutlined,
    name: 'Цены',
    roles: [UserRoles.Administrator],
    route: '/prices'
  },
  {
    icon: StockOutlined,
    name: 'Состояния устройств',
    roles: [UserRoles.Administrator],
    route: '/grades'
  }
]
