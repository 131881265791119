import type { AxiosError, InternalAxiosRequestConfig } from 'axios'

const request = (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token')

  if (token) config.headers.setAuthorization(`Bearer ${token}`)

  return config
}

const response = (error: AxiosError) => {
  if (error?.response?.status === 401) localStorage.removeItem('token')

  return Promise.reject(error)
}

export const authInterceptors = {
  request,
  response
}
