import type { IPagination, ISearchable, IServicePrice } from 'shared/types'
import { client } from '../clients'

const createRecord = (record: IServicePrice) =>
  client.post('/serviceprices', record)

const getList = (page: number) => client.get<
  IPagination<IServicePrice>
>('/serviceprices', { params: { page } })

const getRecord = (recordId: string) =>
  client.get<IServicePrice>(`/serviceprices/${recordId}`)

const updateRecord = (record: IServicePrice) =>
  client.put(`/serviceprices/${record.id}`, record)

const removeRecord = (recordId: string) =>
  client.delete(`/serviceprices/${recordId}`)

const search = (params: ISearchable) =>
  client.get<IServicePrice[]>('/serviceprices/all', { params })

export const servicePrices = {
  record: {
    create: createRecord,
    get: getRecord,
    update: updateRecord,
    remove: removeRecord
  },
  getList,
  search
}
