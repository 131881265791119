import { useEffect, type ReactNode } from 'react'

import * as authModel from 'processes/auth'

export const withAuth = (component: () => ReactNode) => () => {
  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) authModel.initializeAuthModel()
  }, [])

  return component()
}
