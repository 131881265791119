import { Layout, theme } from 'antd'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Fragment,
  type ComponentType,
  type CSSProperties,
  type ReactNode
} from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router'

import {
  useAppLayoutContext,
  type AppLayoutContext,
  type AppLayoutContextProps
} from 'shared/lib'

export interface BaseTemplateProps {
  header: ComponentType<
    Record<string, unknown> &
    Pick<BasicTemplateContextProps, 'title'>
  >
  navigation: ReactNode
}

interface BasicTemplateContextProps extends AppLayoutContextProps {
  stretch?: boolean
  title?: string
  width?: CSSProperties['width']
}

export type BasicTemplateContext = AppLayoutContext<BasicTemplateContextProps>

export const BaseTemplate = ({
  header: Header,
  navigation
}: BaseTemplateProps) => {
  const context = useAppLayoutContext<BasicTemplateContextProps>({
    stretch: true,
    title: ''
  })

  const { stretch = true, title, width } = context.layoutProps

  return (
    <Fragment>
      <Helmet
        defaultTitle='Панель управления Pedant SCL'
        title={title}
        titleTemplate='%s | Панель управления Pedant SCL'
      />

      <Wrapper>
        <Layout.Sider
          collapsed
          collapsedWidth={80}
          theme='light'
        >
          {navigation}
        </Layout.Sider>

        <Layout>
          <LayoutHeader>
            <Header title={title} />
          </LayoutHeader>

          <LayoutContent stretch={stretch}>
            <LayoutContentInner width={width}>
              <Outlet context={context} />
            </LayoutContentInner>
          </LayoutContent>
        </Layout>
      </Wrapper>
    </Fragment>
  )
}

type LayoutContentProps = Pick<BasicTemplateContextProps, 'stretch'>

const LayoutContent = styled(Layout.Content, {
  shouldForwardProp: propName => propName !== 'stretch'
})<LayoutContentProps>`
  display: ${props => props.stretch ? 'flex' : undefined};
`

type LayoutContentInnerProps = Pick<BasicTemplateContextProps, 'width'>

const LayoutContentInner = styled.div<LayoutContentInnerProps>(({ width }) => {
  const { token } = theme.useToken()

  const computed = !!width
    ? `min(calc(100% - 48px), ${typeof width === 'number' ? width + 'px' : width})`
    : 'calc(100% - 48px)'

  return css`
    align-items: stretch;
    background-color: ${token.colorBgContainer};
    border-radius: ${token.borderRadius}px;
    display: flex;
    flex-direction: column;
    margin: calc(var(--indent) * 1.5) 0;
    margin-inline: auto;
    min-height: auto !important;
    padding: calc(var(--indent) * 1.5);
    width: ${computed}
  `
})

const LayoutHeader = styled(Layout.Header)(() => {
  const { token } = theme.useToken()

  return css`
    background-color: ${token.colorBgContainer} !important;
    padding: 0 calc(var(--indent) * 1.5) !important;

    :global {
      .ant-btn {
        padding: 0 !important;
      }
    }
  `
})

const Wrapper = styled(Layout)`
  min-height: 100vh;
`
