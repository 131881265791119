import { useUnit } from 'effector-react'
import React, { type ReactNode, Suspense, useCallback } from 'react'
import { Route, Routes } from 'react-router'

import { $userRole } from 'processes/auth'
import { AppHeader } from 'widgets/app-header'
import { AppNavigation } from 'widgets/app-navigation'
import { lazy, withAuthRoute, withIfAuthRoute } from 'shared/lib'
import { UserRoles, type IRouteConfig } from 'shared/types'
import { BaseTemplate, UnauthorizedTemplate } from 'shared/ui'

const Main = lazy(() => import('./main'))
const DevicesListPage = lazy(() => import('./devices/list'))
const DevicesRecordPage = lazy(() => import('./devices/record'))
const GradesListPage = lazy(() => import('./grades/list'))
const GradesRecordPage = lazy(() => import('./grades/record'))
const MemoriesListPage = lazy(() => import('./device-memories/list'))
const MemoriesRecordPage = lazy(() => import('./device-memories/record'))
const PricesListPage = lazy(() => import('./prices/list'))
const PricesRecordPage = lazy(() => import('./prices/record'))
const ServicePricesListPage = lazy(() => import('./service-prices/list'))
const ServicePricesRecordPage = lazy(() => import('./service-prices/record'))
const TestsListPage = lazy(() => import('./tests/list'))
const TestsRecordPage = lazy(() => import('./tests/record'))
const TreeNodeTestsListPage = lazy(() => import('./tree-node-tests/list'))
const TreeNodeTestsRecordPage = lazy(() => import('./tree-node-tests/record'))
const TreeNodesListPage = lazy(() => import('./tree-nodes/list'))
const TreeNodesRecordPage = lazy(() => import('./tree-nodes/record'))
const UsersListPage = lazy(() => import('./users/list'))
const UsersRecordPage = lazy(() => import('./users/record'))
const ForbiddenPage = lazy(() => import('./forbidden'))
const LoginPage = lazy(() => import('./login'))
const NotFoundPage = lazy(() => import('./not-found'))

const adminRoutes: IRouteConfig[] = [
  {
    index: true,
    roles: [UserRoles.Administrator],
    view: Main
  },
  {
    path: 'devices',
    roles: [UserRoles.Administrator],
    view: DevicesListPage
  },
  {
    path: 'devices/create',
    roles: [UserRoles.Administrator],
    view: DevicesRecordPage
  },
  {
    path: 'devices/:recordId',
    roles: [UserRoles.Administrator],
    view: DevicesRecordPage
  },
  {
    path: 'grades',
    roles: [UserRoles.Administrator],
    view: GradesListPage
  },
  {
    path: 'grades/create',
    roles: [UserRoles.Administrator],
    view: GradesRecordPage
  },
  {
    path: 'grades/:recordId',
    roles: [UserRoles.Administrator],
    view: GradesRecordPage
  },
  {
    path: 'memories',
    roles: [UserRoles.Administrator],
    view: MemoriesListPage
  },
  {
    path: 'memories/create',
    roles: [UserRoles.Administrator],
    view: MemoriesRecordPage
  },
  {
    path: 'memories/:recordId',
    roles: [UserRoles.Administrator],
    view: MemoriesRecordPage
  },
  {
    path: 'prices',
    roles: [UserRoles.Administrator],
    view: PricesListPage
  },
  {
    path: 'prices/create',
    roles: [UserRoles.Administrator],
    view: PricesRecordPage
  },
  {
    path: 'prices/:recordId',
    roles: [UserRoles.Administrator],
    view: PricesRecordPage
  },
  {
    path: 'service-prices',
    roles: [UserRoles.Administrator],
    view: ServicePricesListPage
  },
  {
    path: 'service-prices/create',
    roles: [UserRoles.Administrator],
    view: ServicePricesRecordPage
  },
  {
    path: 'service-prices/:recordId',
    roles: [UserRoles.Administrator],
    view: ServicePricesRecordPage
  },
  {
    path: 'tests',
    roles: [UserRoles.Administrator],
    view: TestsListPage
  },
  {
    path: 'tests/create',
    roles: [UserRoles.Administrator],
    view: TestsRecordPage
  },
  {
    path: 'tests/:recordId',
    roles: [UserRoles.Administrator],
    view: TestsRecordPage
  },
  {
    path: 'tree-node-tests',
    roles: [UserRoles.Administrator],
    view: TreeNodeTestsListPage
  },
  {
    path: 'tree-node-tests/create',
    roles: [UserRoles.Administrator],
    view: TreeNodeTestsRecordPage
  },
  {
    path: 'tree-node-tests/:recordId',
    roles: [UserRoles.Administrator],
    view: TreeNodeTestsRecordPage
  },
  {
    path: 'tree-nodes',
    roles: [UserRoles.Administrator],
    view: TreeNodesListPage
  },
  {
    path: 'tree-nodes/create',
    roles: [UserRoles.Administrator],
    view: TreeNodesRecordPage
  },
  {
    path: 'tree-nodes/:recordId',
    roles: [UserRoles.Administrator],
    view: TreeNodesRecordPage
  },
  {
    path: 'users',
    roles: [UserRoles.Administrator],
    view: UsersListPage
  },
  {
    path: 'users/create',
    roles: [UserRoles.Administrator],
    view: UsersRecordPage
  },
  {
    path: 'users/:recordId',
    roles: [UserRoles.Administrator],
    view: UsersRecordPage
  }
]

const authRoutes: IRouteConfig[] = [
  {
    index: true,
    view: LoginPage
  }
]

export const Routing = () => {
  const userRole = useUnit($userRole)

  const renderRoutes = useCallback((
    routes: IRouteConfig[],
    guard?: (component: ReactNode) => ReactNode
  ) => {
    const result: ReactNode[] = []

    for (const [key, { view: View, roles, ...props }] of routes.entries()) {
      const item = (
        <Route
          {...props}
          element={(
            <Suspense fallback={null}>
              {guard?.(<View/>) ?? <View/>}
            </Suspense>
          )}
          key={key}
        />
      )

      if (!roles?.length || (userRole && roles.includes(userRole))) {
        result.push(item)
      }
    }

    return result
  }, [userRole])

  return (
    <Routes>
      <Route
        element={withIfAuthRoute(<UnauthorizedTemplate />)}
        path='/login'
      >
        {renderRoutes(authRoutes)}
      </Route>

      <Route
        element={withAuthRoute(
          <BaseTemplate
            header={AppHeader}
            navigation={<AppNavigation />}
          />
        )}
        path='/'
      >
        {renderRoutes(adminRoutes)}
      </Route>

      <Route
        element={
          <Suspense fallback={null}>
            <ForbiddenPage />
          </Suspense>
        }
        path='/403'
      />

      <Route
        element={
          <Suspense fallback={null}>
            <NotFoundPage />
          </Suspense>
        }
        path='*'
      />
    </Routes>
  )
}
