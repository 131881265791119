import { useCallback, useState } from 'react'

export type AppLayoutContextProps = Partial<Record<string, unknown>>

export interface AppLayoutContext<LayoutProps extends AppLayoutContextProps> {
  layoutProps: LayoutProps
  onLayoutPropsChange: (props: LayoutProps, replace?: boolean) => void
}

export function useAppLayoutContext<LayoutProps extends AppLayoutContextProps>({
  ...props
}: LayoutProps): AppLayoutContext<LayoutProps> {
  const [layoutProps, setLayoutProps] = useState<LayoutProps>(props)

  const onLayoutPropsChange = useCallback((
    props: LayoutProps,
    replace?: boolean
  ) => {
    setLayoutProps(oldProps => !replace ? ({ ...oldProps, ...props }) : props)
  }, [])

  return { layoutProps, onLayoutPropsChange }
}
