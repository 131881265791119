import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button, Col, Row, Typography } from 'antd'
import { useUnit } from 'effector-react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import * as authModel from 'processes/auth'

export interface IHeaderProps {
  title?: string
}

export const AppHeader = ({ title }: IHeaderProps) => {
  const navigate = useNavigate()
  const isLoggedIn = useUnit(authModel.$isLoggedIn)

  const onAuthButtonClick = useCallback(() => {
    if (isLoggedIn) authModel.logoutFx()

    navigate('/login')
  }, [isLoggedIn, navigate])

  return (
    <Row
      align='middle'
      gutter={16}
      justify='space-between'
      wrap={false}
    >
      <Col flex='auto'>
        <Row
          align='middle'
          gutter={32}
          wrap={false}
        >
          <Col>
            <Row
              align='middle'
              gutter={16}
              wrap={false}
            >
              <LogoContainer>
                <img
                  alt='pedant.ru'
                  src='/assets/logo.svg'
                />
              </LogoContainer>

              <Col>
                <AppTitle
                  ellipsis
                  level={4}
                >
                  {title}
                </AppTitle>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col>
        <Button
          icon={isLoggedIn ? <LogoutOutlined /> : <UserOutlined />}
          onClick={onAuthButtonClick}
          type='text'
        >
          {isLoggedIn ? 'Выход' : 'Вход'}
        </Button>
      </Col>
    </Row>
  )
}

const AppTitle = styled(Typography.Title)`
  margin: 0 !important;
  margin-right: calc(var(--indent) * 3) !important;
`

const LogoContainer = styled(Col)`
  @media (max-width: 640px) {
    display: none;
  }
`
