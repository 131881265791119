import axios, { type AxiosError, type AxiosResponse } from 'axios'

import api, { client } from 'shared/api'
import { authInterceptors } from 'shared/lib'
import type { AppAuthPayload, AppAuthResponse } from 'shared/types'
import { domain } from './_init'

export const getUserFx = domain.createEffect(api.users.getMe)

export const loginFx = domain.createEffect<
  AppAuthPayload,
  AxiosResponse<AppAuthResponse>,
  AxiosError<{ message: string }>
>(params => axios.post<
  AppAuthResponse
>(`${import.meta.env.VITE_API}users/login`, params))

export const logoutFx = domain.createEffect(() => {
  localStorage.removeItem('token')
})

export const setTokenFx = domain.createEffect(({ token }: AppAuthResponse) => {
  localStorage.setItem('token', token)
})

export const addAuthInterceptorsFx = domain.createEffect(() => {
  client.interceptors.request.use(authInterceptors.request)
  client.interceptors.response.use(undefined, authInterceptors.response)
})
