import type { AntdIconProps } from '@ant-design/icons/es/components/AntdIcon'
import type {
  ComponentType,
  ForwardRefExoticComponent,
  RefAttributes
} from 'react'

export type AntdIconComponent<Props = AntdIconProps> =
  ForwardRefExoticComponent<Props & RefAttributes<HTMLSpanElement>>

export interface IRouteConfig {
  index?: boolean
  path?: string
  roles?: UserRoles[]
  view: ComponentType<Record<string, unknown>>
}

export enum TestResultTypes {
  YesOrNo = 'YesOrNo',
  SingleAnswer = 'SingleAnswer'
}

export enum TestTypes {
  Visual = 'Visual',
  External = 'External'
}

export enum UserRoles {
  Administrator = 'Administrator',
  Customer = 'Customer'
}

export interface AppAuthPayload {
  password: string
  username: string
}

export interface AppAuthResponse {
  token: string
}

export interface AppUserData {
  email: string
  role: UserRoles | null
  userData: string
  userName: string
}

export interface ICity extends IEnum {}

export interface IContract extends IEnum {
  cost: number
  departmentCode: string
  issuedDate: string
  issuer: string
  passportNumber: string
  passportSerial: string
  phone: string
  registrationAddress: string
  session: ITestSession
  testSessionId: string | null
}

export interface IDevice {
  deviceMemory: IDeviceMemory | null
  deviceMemoryId: string | null
  id: string
  modelId: string
  modelName: string
}

export interface IDeviceMemory extends IEnum {
  pbModelMemoryId: string | null
  sort: number
}

export interface IEntity {
  id: string
}

export interface IEnum {
  id: string
  name: string
}

export interface IGrade extends IEnum {
  downGrade: boolean
  isRedeemable: boolean
  priority: number
}

export interface IModelMemory extends IEnum {
  deleted: boolean
  shopatAmoCrmId: number | null
  sort: number | null
}

export interface IModel extends IEnum {}

export interface IPagination<T = unknown> {
  hasNextPage: boolean
  hasPreviousPage: boolean
  items: T[]
  pageIndex: number
  totalPages: number
  totalItems: number
}

export interface IPartner extends IEnum {
  sort: number
}

export interface IPrice {
  cityId: string | null
  cityName: string
  device: IDevice
  deviceId: string
  grade: IGrade
  gradeId: string
  partner: IPartner | null
  partnerId: string | null
  id: string
  value: number
}

export interface ISearchable {
  limit?: number
  name?: string
}

export interface IService extends IEnum {}

export interface IServicePrice extends IEnum {
  node: ITreeNode | null
  nodeId: string
  serviceId: string
  price: number
}

export interface ITest extends IEnum {
  answersCount: number
  order: number
  resultType: TestResultTypes
  testType: TestTypes
}

export interface ITestResult {
  chosenAnswer: ITreeNodeTestAnswer | null
  chosenAnswerId: string | null
  id: string
  nodeTest: ITreeNodeTest
  nodeTestId: string
  results: number[]
  test: ITest
  testId: string
  testSessionId: string
}

export interface ITestSession {
  approved: boolean
  basicPrice: number | null
  cityId: string | null
  contract: IContract
  dateTime: string
  device: IDevice
  deviceId: string
  diagnosticGoalId: string | null
  done: boolean
  number: string
  id: string
  imei: string
  partnerId: string | null
  serialNumber: string
  testResults: ITestResult[]
  userId: string
}

export interface ITreeNode extends IEnum {
  modelId: string
  order: number
  parent: ITreeNode
  parentId: string
}

export interface ITreeNodeTest {
  answers: ITreeNodeTestAnswer[]
  deleted: boolean
  description: string
  id: string
  images: ITreeNodeTestImage[]
  node: ITreeNode
  nodeId: string
  percent: number
  question: string
  serviceId: string | null
  serviceName: string
  sort: number
  test: ITest
  testId: string
}

export interface ITreeNodeTestAnswer {
  index: number
  description: string
  grade: IGrade | null
  gradeId: string | null
}

export interface ITreeNodeTestImage {
  description: string
  order: number
  url: string
}

export interface IUser {
  broker: string
  email: string
  id: string
  password?: string
  phone: string
  role: string
  userName: string
}
