import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Spin, theme } from 'antd'

export const AppLoader = () => (
  <Wrapper>
    <Spin
      size='large'
      spinning
    />
  </Wrapper>
)

const Wrapper = styled.div(() => {
  const { token } = theme.useToken()

  return css`
    align-items: center;
    background-color: ${token.colorBgBase};
    display: grid;
    justify-content: center;
    max-height: 100%;
    min-height: 100vh;
  `
})
