import axios from 'axios'

export const client = axios.create({ baseURL: import.meta.env.VITE_API })

client.interceptors.request.use(config => {
  config.headers.set('Accept-Language', 'ru-RU')
  config.params = { ...config.params, culture: 'ru-ru' }

  return config
})

export const seq = axios.create({
  baseURL: import.meta.env.VITE_SEQ_URL,
  headers: { 'Content-Type': 'application/vnd.serilog.clef' }
})
