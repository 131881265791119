import type { IDevice, IPagination, ISearchable } from 'shared/types'
import { client } from '../clients'

const createRecord = (record: IDevice) => client.post('/devices', record)

const getList = (page: number) => client.get<IPagination<IDevice>>('/devices', {
  params: { page }
})

const getRecord = (recordId: string) =>
  client.get<IDevice>(`/devices/${recordId}`)

const updateRecord = (record: IDevice) =>
  client.put(`/devices/${record.id}`, record)

const removeRecord = (recordId: string) => client.delete(`/devices/${recordId}`)

const search = (params: ISearchable) => client.get<IDevice[]>('/devices/all', {
  params
})

export const devices = {
  record: {
    create: createRecord,
    get: getRecord,
    update: updateRecord,
    remove: removeRecord
  },
  getList,
  search
}
