import styled from '@emotion/styled'
import { Card } from 'antd'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router'

import {
  useAppLayoutContext,
  type AppLayoutContext,
  type AppLayoutContextProps
} from 'shared/lib'

interface UnauthorizedTemplateContextProps extends AppLayoutContextProps {
  title?: string
}

export type UnauthorizedTemplateContext = AppLayoutContext<
  UnauthorizedTemplateContextProps
>

export const UnauthorizedTemplate = () => {
  const context = useAppLayoutContext<UnauthorizedTemplateContextProps>({
    title: ''
  })

  const { title } = context.layoutProps

  return (
    <Fragment>
      <Helmet
        defaultTitle='Pedant Тестирование'
        title={title}
        titleTemplate='%s — Pedant Тестирование'
      />

      <Wrapper>
        <Content>
          <Outlet context={context} />
        </Content>
      </Wrapper>
    </Fragment>
  )
}

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  justify-content: center;
  max-height: 100%;
  min-height: 100vh;
`

const Content = styled(Card)`
  margin: var(--indent);
  width: 460px;
`
