import type { IModel, ISearchable } from 'shared/types'
import { client } from '../clients'

const search = (params: ISearchable) => client.get<IModel[]>('/models', {
  params
})

export const models = {
  search
}
