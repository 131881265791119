import { useUnit } from 'effector-react'
import type { ReactNode } from 'react'
import { Navigate } from 'react-router'

import { $isLoggedIn } from 'processes/auth'

export const withIfAuthRoute = (component: ReactNode) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isAuthorized = useUnit($isLoggedIn)

  if (!isAuthorized) return component

  return (
    <Navigate
      replace
      to='/'
    />
  )
}
