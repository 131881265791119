import type { IPagination, ISearchable, ITest } from 'shared/types'
import { client } from '../clients'

const createRecord = (record: ITest) => client.post('/tests', record)

const getList = (page: number) => client.get<IPagination<ITest>>('/tests', {
  params: { page }
})

const getRecord = (recordId: string) => client.get<ITest>(`/tests/${recordId}`)

const updateRecord = (record: ITest) =>
  client.put(`/tests/${record.id}`, record)

const removeRecord = (recordId: string) => client.delete(`/tests/${recordId}`)

const search = (params: ISearchable) => client.get<ITest[]>('/tests/all', {
  params
})

export const tests = {
  record: {
    create: createRecord,
    get: getRecord,
    update: updateRecord,
    remove: removeRecord
  },
  getList,
  search
}
