import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import ReactDOM from 'react-dom/client'

import App from './app'

dayjs.locale('ru')

const root = document.querySelector('#root')
if (root) ReactDOM.createRoot(root).render(<App />)
