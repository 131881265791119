import type { IDeviceMemory, IPagination, ISearchable } from 'shared/types'
import { client } from '../clients'

const createRecord = (record: IDeviceMemory) => client.post('/memories', record)

const getList = (page: number) =>
  client.get<IPagination<IDeviceMemory>>('/memories', {
    params: { page }
  })

const getRecord = (recordId: string) =>
  client.get<IDeviceMemory>(`/memories/${recordId}`)

const updateRecord = (record: IDeviceMemory) =>
  client.put(`/memories/${record.id}`, record)

const removeRecord = (recordId: string) =>
  client.delete(`/memories/${recordId}`)

const search = (params: ISearchable) =>
  client.get<IDeviceMemory[]>('/memories/all', { params })

export const deviceMemories = {
  record: {
    create: createRecord,
    get: getRecord,
    update: updateRecord,
    remove: removeRecord
  },
  getList,
  search
}
